import React, { Component } from 'react'
import Lightbox from 'react-image-lightbox'
import Layout from '../../components/layout/layout'
import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'
import SEO from "../../components/seo"
import CCBreadcrumb from '../../components/layout/breadcrumb'
import SideBarSeTwo from "../../components/layout/sidemenu/sidemenu_se_two"

import Alert from 'react-bootstrap/Alert'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'

import ContentHeader from '../../components/page-sections/content-header'
import FocusQuestion from '../../components/page-sections/focus-question'
import Introduction from '../../components/page-sections/introduction'
import ButtonTop from '../../components/buttons/button-top'
import ButtonNext from '../../components/buttons/button-next'
import rowImage from '../../queries/images/row-image'

import 'react-image-lightbox/style.css'

import unit2Icon from '../../images/icons/unit_02_icon.svg'
import pHDissolved15 from '../../images/student-pages/unit-2/15_ph_dissovled_oxygen.gif'
import CO2Natural19 from '../../images/student-pages/unit-2/19_co2_natural_process_mauna_2005.gif'
import southAtlantic20 from '../../images/student-pages/unit-2/20_south_atlantic_ocean.jpg'
import dustStorm21 from '../../images/student-pages/unit-2/21_dust_storm_africa.jpg'


const ExploringLimits22 = class extends
Component {
  constructor(props) {
    super(props);

    this.state = {
      photoIndex: 0,
      isOpen: false,
    };
  }

  render() {
    const { photoIndex, isOpen } = this.state;
    const images = [
      this.props.data.imageAE1.childImageSharp.fluid.src,
      this.props.data.imageAE2.childImageSharp.fluid.src,
      this.props.data.imageAE3.childImageSharp.fluid.src,
      this.props.data.imageAE4.childImageSharp.fluid.src,
      this.props.data.imageAE5.childImageSharp.fluid.src,
      this.props.data.imageAE6.childImageSharp.fluid.src,
      this.props.data.imageAE7.childImageSharp.fluid.src,
      this.props.data.imageAE8.childImageSharp.fluid.src,
    ]

    return (
      <Layout location={this.props.location.pathname}>
        <SEO title="Carbon Connections - 2.2 Exploring Limits" />
        <Container className="mb-5" fluid>
          <Row>
            <Col md="auto">
              <SideBarSeTwo location={this.props.location.pathname} />
            </Col>
            <Col>
              <CCBreadcrumb
                pathname={this.props.location.pathname}
                title={'2.2 Exploring Limits'}
                replace={this.props.replace}
              />
              <Alert
                className="w-100"
                variant="warning"
              >
                BSCS is maintaining this educational resource for archival purposes. Some information may be out of date. We are no longer supporting the resource for classroom use.
              </Alert>
              <Card>
                <Card.Body>

                  <ContentHeader
                    icon={ unit2Icon }
                    iconAlt="Unit 2 icon"
                    studentPageHeader="Unit 2: Carbon Now"
                    unitNumber="2.2"
                    studentPageHeaderSub="Exploring Limits"
                    sectionA={ <>Aquatic Model</> }
                    sectionALink="/unit-2/2.2-exploring-limits/#aquatic-model"
                    sectionB={ <>Photosynthesis and Respiration</> }
                    sectionBLink="/unit-2/2.2-exploring-limits/#photosynthesis-and-respiration"
                    sectionC={ <>Aquarium Ups and Downs</> }
                    sectionCLink="/unit-2/2.2-exploring-limits/#aquariums-ups-and-downs"
                    sectionD={ <>Going Global</> }
                    sectionDLink="/unit-2/2.2-exploring-limits/#going-global"
                    sectionE={ <>Reflect and Connect</> }
                    sectionELink="/unit-2/2.2-exploring-limits/#reflect-and-connect"
                  />

                  <FocusQuestion
                    focusQuestionBodyText="What are some processes of the biosphere that move carbon in the Earth system?"
                  />

                  <Introduction
                    paragraphOne={
                      <>
                        <p>Carbon moves among oceans, atmosphere, and land. The carbon cycle is part of a record of past climates on Earth, but it also acts on periods of days to years. In Lesson 2.1, you started investigating how carbon moves and changes forms in the Earth system. You looked at satellite data that showed carbon in the atmosphere and how it changes month to month. You also considered organisms with carbon, such as plants and the air from your lungs.</p>

                        <Row className="d-md-none">
                          <Col>
                            <Img
                              className="rounded img-fluid mb-3 imageBorder"
                              fluid={this.props.data.image10.childImageSharp.fluid}
                              alt="Biomass carbon map"
                            />
                          </Col>
                        </Row>
                        <Img
                          className="largeImageCenter img-fluid d-none d-md-block mb-4 imageBorder"
                          fluid={this.props.data.image10.childImageSharp.fluid}
                          alt="Biomass carbon map"
                        />
                      </>
                    }

                    paragraphTwo={
                      <>
                        <p>But there is another part of Earth that stores a huge amount of carbon: the ocean. It was in your box diagram in Lesson 2.1. In this lesson, you will focus on the oceans. This will help you better understand how carbon cycles among the land, atmosphere, and oceans. In Lesson 2.2, you will learn that:</p>
                        <ul>
                          <li>Photosynthesis and respiration move CO<sub>2</sub> into and out of the biosphere.</li>
                          <li>We can use scale models to understand larger-scale processes on Earth.</li>
                          <li>Different factors, such as patterns of light, affect the rate of photosynthesis and respiration.</li>
                        </ul>
                      </>
                    }

                    paragraphThree={
                      <>
                        <p>Use the focus question to help to guide your thinking about how carbon moves in the Earth system.</p>
                      </>
                    }
                  />

                  <ButtonTop />

                  <Row>
                    <Col>
                      <Card className="mb-4">
                        <Card.Header id="aquatic-model" className="cardHeader">
                          <h2>
                            A. <span className="headerFontBlue">Aquatic Model</span>
                          </h2>
                        </Card.Header>
                        <Card.Body className="cardBodyBackground">
                          <p>
                            In Unit 1, you investigated carbon moving into and out of water. When carbon moves into water, it becomes part of the aquatic ecosystems. Those ecosystems could be marine or freshwater. Your class will set up a model of an aquatic ecosystem and monitor carbon for one to two weeks. And as you learn more about the carbon cycle and climate, you will see how that model represents carbon in the Earth system.
                          </p>

                          <p>
                            Your model uses algae in containers. Your teacher will show you the setup. You will start with a culture of algae, fertilizer (that is, a "growth medium"), and some light. Your goal is to gather evidence that carbon is moving into the system.
                          </p>

                          <ol>
                            <li>
                              Complete the following steps with a partner. Be ready to share your ideas in a class discussion.
                              <ol type="a">
                                <li>What you think will happen in the containers?</li>
                                <li>What data could you collect to serve as evidence that carbon is moving into the system?</li>
                                <li>What factor do you think is more important to the growing algae: the light or the growth medium? How could you test this?</li>
                              </ol>
                            </li>
                          </ol>

                          <Row className="d-md-none">
                            <Col>
                              <Img
                                className="rounded img-fluid mb-3 imageBorder"
                                fluid={this.props.data.image11.childImageSharp.fluid}
                                alt="Algae experiment"
                              />
                            </Col>
                          </Row>
                          <Img
                            className="largeImageCenter img-fluid d-none d-md-block imageBorder"
                            fluid={this.props.data.image11.childImageSharp.fluid}
                            alt="Algae experiment"
                          />
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>

                  <ButtonTop />

                  <Row>
                    <Col>
                      <Card className="mb-4">
                        <Card.Header id="photosynthesis-and-respiration" className="cardHeader">
                          <h2>
                            B. <span className="headerFontBlue">Photosynthesis and Respiration</span>
                          </h2>
                        </Card.Header>
                        <Card.Body className="cardBodyBackground">
                          <p>
                            As you started exploring in Unit 1, carbon moves among oceans, atmosphere, and land. In some settings, carbon moves quickly&mdash;over periods of days or years. But what are some of the main processes in the biosphere that affect <em>how</em> carbon moves in the Earth system? Two vital processes involve organisms.
                          </p>

                          <p>
                            <strong>Photosynthesis</strong> is the process where plants use carbon dioxide (CO<sub>2</sub>), water (H<sub>2</sub>O), and sunlight to make glucose (C<sub>6</sub>H<sub>12</sub>O<sub>6</sub>) and oxygen (O<sub>2</sub>). It also moves carbon from the atmosphere to the biosphere. Only some organisms are able to use light ("<em>photo</em>&#8208;") to make, or <em>synthesize</em>, their cells and tissues, however. These organisms are called <strong>primary producers</strong>. These include plants, algae, and some bacteria.
                          </p>

                          <Row className="d-md-none">
                            <Col>
                              <Img
                                className="rounded img-fluid mb-3"
                                fluid={this.props.data.image12.childImageSharp.fluid}
                                alt="Photosynthesis in plants"
                              />
                            </Col>
                          </Row>
                          <Img
                            className="smallImageRight ml-2 img-fluid d-none d-md-block"
                            fluid={this.props.data.image12.childImageSharp.fluid}
                            alt="Photosynthesis in plants"
                          />

                          <p>
                            Primary producers are the base of the food web. All other organisms rely on primary producers. For example, a horse is not a primary producer, but it does need to eat, or consume, grass. Thus, the horse is a <strong>consumer</strong>.
                          </p>

                          <p>
                            All the organic molecules, whether in an individual organism or an entire ecosystem, are called <strong>biomass</strong>. The diagram at the beginning of this lesson shows biomass for part of Earth.
                          </p>

                          <p>
                            <strong>Photosynthesis:</strong> <br />
                            6CO<sub>2</sub> + 6H<sub>2</sub>O + E &rarr; C<sub>6</sub>H<sub>12</sub>O<sub>6</sub> + 6O<sub>2</sub> <br />
                            carbon dioxide + water + energy from the sun &rarr; glucose + oxygen
                          </p>

                          <p>
                            A pine tree is a good way to show the importance of this equation. It starts from a seed, but where does the mass come from for a seed to grow into a tree? Remember in our last lesson that we discovered that the mass comes from CO<sub>2</sub> in the air. Though it does not seem like CO<sub>2</sub> gas can produce a large tree, it does. After making glucose, the plant uses the glucose to make the cells and tissues of bark, wood, roots, and needles. It may seem hard to believe that organisms of the biosphere rely on carbon gas in the air, but they do. Whether cells and tissues are from producers or consumers, nearly all the biomass in ecosystems originates from CO<sub>2</sub> in the air. Watch the time-lapse <Link to="unit-2/2.1-moving-carbon#radish-video">Radish Seed video</Link> again of a seed growing into a plant using only a wet paper towel. This is photosynthesis in fast-motion.
                          </p>

                          <p>
                            In Lesson 2.1, question 5b asked you where the mass of a growing plant came from. Check in your notebook for how you first answered the question. Revise your answer now based on what you are learning about photosynthesis.
                          </p>

                          <Row className="d-md-none">
                            <Col>
                              <Img
                                className="rounded img-fluid mb-3"
                                fluid={this.props.data.image13.childImageSharp.fluid}
                                alt="Seed tree"
                              />
                            </Col>
                          </Row>
                          <Img
                            className="largeImageCenter img-fluid d-none d-md-block mb-3"
                            fluid={this.props.data.image13.childImageSharp.fluid}
                            alt="Seed tree"
                          />

                          <p>
                            But the carbon may not stay in organic molecules for long. What if the organism is using energy and burning calories to stay alive? Even organisms such as trees, plants, and algae have to use energy to stay alive. Or, if the organism dies, where does that carbon go? You have probably seen plants or foods that are rotten and decaying. <strong>Decomposers</strong> include the bacteria, fungi, worms, or insects that cause rot and decay. Decomposers get their energy from organic matter. You saw this in the Decomposition video about leaf and wood decomposition on the forest floor. In fact, nearly all organisms get their energy this way. The process is called "cellular respiration" because it happens in the cells of organisms. For an organism breathing oxygen (O<sub>2</sub>), its "food" can be represented as the sugar glucose (C<sub>6</sub>H<sub>12</sub>O<sub>6</sub>). <strong>Cellular respiration</strong> is the process of using oxygen and glucose to supply energy for the organism. This process also releases CO<sub>2</sub> and water. The respiration reaction can be represented as: <br />
                            <strong>Cellular Respiration:</strong> <br />
                            C<sub>6</sub>H<sub>12</sub>O<sub>6</sub> + 6O<sub>2</sub>   &rarr; 6CO<sub>2</sub> + 6H<sub>2</sub>O + E <br />
                            glucose + oxygen &rarr; carbon dioxide + water + energy for the organism
                          </p>

                          <p>
                            For some organisms, it might not appear that they have energy needs. Consider a tree or plant, for example. It does not expend energy like you do when you walk, run, or move your arms. However, it does require a lot of energy to grow the different tissues in limbs, leaves, roots, or flowers. Imagine a tree leafing out in the spring. This growth spurt is similar to the energy you use to grow. Some types of plants have poisons or molecules that keep consumers from eating their leaves. Making those extra kinds of molecules requires extra energy. That energy comes from the breakdown of organic molecules (such as glucose) during cellular respiration.
                          </p>

                          <p>
                            Get with a partner to answer the following questions about photosynthesis and respiration.
                          </p>

                          <ol start="2">
                            <li>
                              You excrete some of the food that you eat. The rest of the food releases energy to cells in your body.
                              <ol type="a">
                                <li>What process provides your cells with energy?</li>
                                <li>How do you get rid of the carbon from that food?</li>
                                <li>
                                  What specific evidence from Carbon Connections do you have to support your answer? <br />
                                  <em>Hint: Think back to Lesson 2.1.</em>
                                </li>
                              </ol>
                            </li>
                            <li>Think back to the example of leaves piling up on the forest floor. Where does the carbon go from those leaves, and what form is it in?</li>
                            <li>
                              Look again at the formulas for photosynthesis and respiration.
                              <ol type="a">
                                <li>Consider a carbon system (such as the algae model) or a larger system (such as the Northern Hemisphere). If you were trying to track the rate of photosynthesis, what could you monitor in the reaction?</li>
                                <li>
                                  For the same system, what could you monitor in the reaction if you were trying to track the rate of respiration? <br />
                                  <strong>Photosynthesis:</strong> <br />
                                  6CO<sub>2</sub> + 6H<sub>2</sub>O + E &rarr; C<sub>6</sub>H<sub>12</sub>O<sub>6</sub> + 6O<sub>2</sub> <br />
                                  carbon dioxide + water + energy from the sun &rarr; glucose + oxygen <br />
                                  <strong>Cellular Respiration:</strong> <br />
                                  C<sub>6</sub>H<sub>12</sub>O<sub>6</sub> + 6O<sub>2</sub> &rarr; 6CO<sub>2</sub> + 6H<sub>2</sub>O + E <br />
                                  glucose + oxygen &rarr; carbon dioxide + water + energy for the organism
                                </li>
                              </ol>
                            </li>
                            <li>
                              You will keep your algae model under a light. Imagine that you kept the algae in the dark and gave it other food, such as glucose in the water. Predict what you think would happen to the CO<sub>2</sub> levels and pH of the water with time. <br />
                              <em>Hint: It will help you to refer back to Lesson 2.1, where you wrote in a table the relationship between CO<sub>2</sub> and pH in water.</em>
                            </li>
                          </ol>

                          <p>
                            Perhaps your class cannot do the algae model. To see the results of another class of students who did the algae model, view the images at the bottom.
                          </p>

                          <Button
                            block
                            className="btn-primary mb-4"
                            size="lg"
                            onClick={() => this.setState({ isOpen: true })}
                          >
                            Open Algae Model Experiment
                          </Button>

                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>

                  <ButtonTop />

                  <Row>
                    <Col>
                      <Card className="mb-4">
                        <Card.Header id="aquariums-ups-and-downs" className="cardHeader">
                          <h2>
                            C. <span className="headerFontBlue">Aquarium Ups and Downs</span>
                          </h2>
                        </Card.Header>
                        <Card.Body className="cardBodyBackground">
                          <Row className="d-md-none">
                            <Col>
                              <Img
                                className="rounded img-fluid mb-3 imageBorder"
                                fluid={this.props.data.image14.childImageSharp.fluid}
                                alt="Aquarium"
                              />
                            </Col>
                          </Row>
                          <Img
                            className="smallImageRight img-fluid d-none d-md-block imageBorder ml-3"
                            fluid={this.props.data.image14.childImageSharp.fluid}
                            alt="Aquarium"
                          />

                          <p>
                            In the carbon cycle, carbon moves at different rates. For an ecosystem or region, this depends on whether photosynthesis is faster than respiration (P&gt;R), or whether respiration is faster than photosynthesis (R&lt;P).
                          </p>

                          <p>
                            You can explore real data to find out if photosynthesis or respiration is faster. These data were collected by high school students. The students had learned that oxygen was produced in photosynthesis. They put a large number of aquatic plants in an aquarium. They put the aquarium near a window in a room with no other lights. Using probes, they measured the dissolved oxygen and pH for two days. The student data is in the following graph. With a partner, explore the graph of the student data.
                          </p>

                          <Row className="d-md-none">
                            <Col>
                              <img
                                className="rounded img-fluid mb-3"
                                src={ pHDissolved15 }
                                alt="pH and amount of dissolved oxygen over 2 days"
                              />
                            </Col>
                          </Row>
                          <img
                            className="largeImageCenter img-fluid d-none d-md-block mb-3"
                            src={ pHDissolved15 }
                            alt="pH and amount of dissolved oxygen over 2 days"
                          />

                          <ol start="6">
                            <li>
                              Answer these questions about photosynthesis and respiration in the aquarium when it is dark at night. Copy a table into your science notebook like the one shown. Record your findings in the table for the steps below.
                              <ol type="a">
                                <li>At night, does photosynthesis (P) or respiration (R) have a faster rate? For example, if photosynthesis is faster, show this as "P&gt;R" in the table.</li>
                                <li>Is oxygen being produced or consumed by the plants? Draw an arrow in the same row in the table below to show an increase (&uarr;) or decrease (&darr;).</li>
                                <li>At night, is CO<sub>2</sub> being produced or consumed by the plants? Draw another arrow in the same row in the table below to show an increase (&uarr;) or decrease (&darr;).</li>
                                <li>The pH of water around the plants is a proxy for CO<sub>2</sub> in water. Referring back to Lesson 2.1, step 6, (or even Lesson 1.1), how does pH change when CO<sub>2</sub> in water changes?</li>
                              </ol>
                            </li>
                          </ol>

                          <table className="table table-bordered table-hover table-responsive-md studentTable">
                            <thead>
                              <tr className="text-center">
                                <th className="table-active" scope="col">Time</th>
                                <th className="table-active" scope="col">Rate of Photosynthesis (P) versus Respiration (R)</th>
                                <th className="table-active" scope="col">Oxygen (O<sub>2</sub>) in Water</th>
                                <th className="table-active" scope="col">Carbon Dioxide (CO<sub>2</sub>) in Water</th>
                                <th className="table-active" scope="col">pH of Water</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>Night</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                              </tr>
                              <tr>
                                <td>Day</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                              </tr>
                            </tbody>
                          </table>

                          <ol start="7">
                            <li>Repeat the steps above for the aquarium during the day.</li>
                            <li>The students who graphed the data could have shown where day and night are. That should never happen in your school! Identify the times when you think the plants are in the light, and when they are in the dark. Do this by shading the graph for the time that you think is dark.</li>
                          </ol>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>

                  <ButtonTop />

                  <Row>
                    <Col>
                      <Card className="mb-4">
                        <Card.Header id="going-global" className="cardHeader">
                          <h2>
                            D. <span className="headerFontBlue">Going Global</span>
                          </h2>
                        </Card.Header>
                        <Card.Body className="cardBodyBackground">
                          <p>
                            Now, let's go global! You can explore whether the physical processes in the aquarium or your algae model are also reflected in three key parts of the global biosphere. You will look at a few sources of data below for <strong>land</strong>, <strong>oceans</strong>, and <strong>atmosphere</strong>. Complete these steps with a partner.
                          </p>

                          <ol start="9">
                            <li>
                              The first set of data shows the amount of vegetation on all the Earth's land.
                              <ol type="a">
                                <li>
                                  Read the following to help you understand the video: <br />
                                  Another way to indicate whether photosynthesis is greater than respiration is by knowing the <strong>net primary productivity</strong>. This indicates how much photosynthesis is being done by primary producers in ecosystems. High values mean that the rate of photosynthesis is much greater than respiration. Carbon dioxide (CO<sub>2</sub>) leaves the atmosphere and goes to the plant to support its growth.
                                </li>
                                <li>Imagine that the video did not have months on it. Would you be able to tell when it was winter where you live? How?</li>
                              </ol>
                            </li>
                          </ol>

                          <div id="" className="embed-container mb-4">
                            <iframe title="MOD17A2_M_PSN" className="videoSetting" src="https://player.vimeo.com/video/307874304" frameBorder="0" webkitallowfullscreen={toString('true')} mozallowfullscreen={toString('true')} allowFullScreen></iframe>
                          </div>

                          <Row className="d-md-none">
                            <Col>
                              <Img
                                className="rounded img-fluid mb-3 imageBorder"
                                fluid={this.props.data.image16.childImageSharp.fluid}
                                alt="Chlorophyll in plants"
                              />
                            </Col>
                          </Row>
                          <Img
                            className="smallImageRight img-fluid d-none d-md-block imageBorder ml-3 mb-3"
                            fluid={this.props.data.image16.childImageSharp.fluid}
                            alt="Chlorophyll in plants"
                          />

                          <ol start="10">
                            <li>
                              Photosynthesis and respiration also occur in the oceans. The image shows high productivity in the southern Atlantic Ocean (green, light blue). The second video shows productivity in all oceans.
                              <ol type="a">
                                <li>
                                  Read the following to help you understand the video. <br />
                                  At certain places and times, the ocean comes to life. Productivity increases dramatically as photosynthesizing organisms reproduce and thrive. They need carbon to do this. Some of the main photosynthesizing organisms in the ocean are algae and blue-green bacteria (cyanobacteria). Scientists use NASA satellites to estimate the productivity at those times. They also measure the amount of chlorophyll in the oceans. <strong>Chlorophyll</strong> is a molecule used by organisms in photosynthesis. It is the reason that plants and other producers are green.
                                </li>
                                <li>Look at this key for ocean productivity from the video. The scale is milligrams of chlorophyll per cubic meter of ocean water. What colors indicate the highest productivity?</li>

                                <Row className="d-md-none">
                                  <Col>
                                    <Img
                                      className="rounded img-fluid mb-3 mt-3 imageBorder"
                                      fluid={this.props.data.image17.childImageSharp.fluid}
                                      alt="Scale is milligrams of chlorophyll per cubic meter of ocean water"
                                    />
                                  </Col>
                                </Row>
                                <Img
                                  className="largeImageCenter img-fluid d-none d-md-block imageBorder mb-3 mt-3"
                                  fluid={this.props.data.image17.childImageSharp.fluid}
                                  alt="Scale is milligrams of chlorophyll per cubic meter of ocean water"
                                />

                                <li>In what parts of the ocean is productivity highest? What regions are the lowest?</li>
                              </ol>
                            </li>
                          </ol>

                          <div id="" className="embed-container mb-3">
                            <iframe title="bios" className="videoSetting" src="https://player.vimeo.com/video/327616779" frameBorder="0" webkitallowfullscreen={toString('true')} mozallowfullscreen={toString('true')} allowFullScreen></iframe>
                          </div>

                          <ol start="11">
                            <li>
                              Another part of the biosphere does not have much biomass, but it has a lot of carbon: the atmosphere! This is included so you can see the full "<em>breathing biosphere</em>."
                              <ol type="a">
                                <li>
                                  Read the following about carbon in the atmosphere. <br />
                                  Exploring CO<sub>2</sub> levels in the atmosphere helps you learn about carbon in the biosphere. Scientists gather important data comes from Mauna Loa observatory in Hawaii. Air arriving there is a mixture of air that has passed over Europe, Asia, and the Pacific Ocean. The CO<sub>2</sub> levels in the air tell of the photosynthesis and respiration of the ecosystems that the air passed over before arriving at Mauna Loa.
                                </li>

                                <Row className="d-md-none">
                                  <Col>
                                    <Img
                                      className="rounded img-fluid mb-3 mt-3"
                                      fluid={this.props.data.image18.childImageSharp.fluid}
                                      alt="Mauna Loa, Hawaii map"
                                    />
                                  </Col>
                                </Row>
                                <Img
                                  className="largeImageCenter img-fluid d-none d-md-block mb-3 mt-3"
                                  fluid={this.props.data.image18.childImageSharp.fluid}
                                  alt="Mauna Loa, Hawaii map"
                                />

                                <li>
                                  Note these points in the graph. Over the year, it shows a breathing biosphere.
                                  <ul>
                                    <li>The y-axis is CO<sub>2</sub> levels of the air crossing the Pacific Ocean and arriving to Mauna Loa from Europe and Asia.</li>
                                    <li>The x-axis is months of the year, January through December.</li>
                                  </ul>
                                </li>
                                <li>
                                  In the fall and winter, CO<sub>2</sub> levels increase. What does that tell you about the rate of photosynthesis and respiration? Use the symbols &gt;, &lt;, or &#61; to complete the following sentence: <br />
                                  <em>In fall and winter in Europe and Asia, the rate of photosynthesis is &#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;  the rate of cellular respiration.</em>
                                </li>

                                <Row className="d-md-none">
                                  <Col>
                                    <img
                                      className="rounded img-fluid mb-3 mt-3"
                                      src={ CO2Natural19 }
                                      alt="carbon dioxide, natural processes, Mauna Loa, 2005"
                                    />
                                  </Col>
                                </Row>
                                <img
                                  className="largeImageCenter img-fluid d-none d-md-block mb-3 mt-3"
                                  src={ CO2Natural19 }
                                  alt="carbon dioxide, natural processes, Mauna Loa, 2005"
                                />

                                <li>
                                  In spring and summer, CO<sub>2</sub> levels decrease. What does that tell you about the rate of photosynthesis and respiration? Use the symbols &gt;, &lt;, or &ge; to complete the sentence below: <br />
                                  <em>In spring and summer in Europe and Asia, the rate of photosynthesis is &#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F; the rate of cellular respiration.</em>
                                </li>
                                <li>
                                  You can see all these parts of the breathing biosphere in a single video. Read the following questions with a partner, and then watch the CO<sub>2</sub> Levels video. (You can watch the video more than once.)
                                  <ol type="a">
                                    <li>How many years are shown in the video?</li>
                                    <li>When the productivity is high in the Northern Hemisphere, what is happening to the CO<sub>2</sub> levels?</li>
                                  </ol>
                                </li>
                              </ol>
                            </li>
                          </ol>

                          <div id="" className="embed-container">
                            <iframe title="biosphere pacific" className="videoSetting" src="https://player.vimeo.com/video/307875635" frameBorder="0" webkitallowfullscreen={toString('true')} mozallowfullscreen={toString('true')} allowFullScreen></iframe>
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>

                  <ButtonTop />

                  <Row>
                    <Col>
                      <Card className="mb-4">
                        <Card.Header id="reflect-and-connect" className="cardHeader">
                          <h2>
                            E. <span className="headerFontBlue">Reflect and Connect</span>
                          </h2>
                        </Card.Header>
                        <Card.Body className="cardBodyBackground">
                          <p>Complete Lesson 2.2 with the questions below.</p>

                          <ol start="13">
                            <li>Your algae model will be in the light the whole time. This will help the algae increase biomass faster. Do you expect your results to show a similar pattern for CO<sub>2</sub> levels in the aquarium investigation?</li>
                            <li>How are the CO<sub>2</sub> patterns at Mauna Loa similar to, or different from, the aquarium experiment?</li>
                            <li>Return to your focus question to describe some processes in the biosphere that move carbon. List the processes and explain how they move the carbon.</li>
                            <li>Write in your notebook your explanation of the breathing biosphere. What does this phrase mean to you?</li>
                            <li>Factors other than light can affect the rate of photosynthesis and respiration in the biosphere. To learn more about them, see Explore More: Rate-Limiting Factors on Photosynthesis.</li>
                          </ol>

                          <p>
                            In this unit, you are studying how to track carbon as it moves in the Earth system. Knowing how carbon cycles today will help you make informed decisions in the future. It also helps us understand changes to the amount of carbon in the atmosphere in recent times. Understanding changes in the amount of carbon is important in thinking about future climate.
                          </p>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>

                  <ButtonTop />

                  <Row>
                    <Col>
                      <Card className="mb-4">
                        <Card.Header id="reflect-and-connect" className="cardHeader">
                          <h2>
                            <span className="headerFontBlue">Explore More: Rate-Limiting Factors on Photosynthesis</span>
                          </h2>
                        </Card.Header>
                        <Card.Body className="cardBodyBackground">
                          <p className="lead">Rate-Limiting Factors on Photosynthesis</p>
                          <p className="lead">South Atlantic Ocean</p>

                          <Row className="d-md-none">
                            <Col>
                              <img
                                className="rounded img-fluid mb-3 imageBorder"
                                src={ southAtlantic20 }
                                alt="South Atlantic Ocean"
                              />
                            </Col>
                          </Row>
                          <img
                            className="smallImageRight img-fluid d-none d-md-block imageBorder ml-2"
                            src={ southAtlantic20 }
                            alt="South Atlantic Ocean"
                          />

                          <p>
                            You have seen how patterns of light affect the rates of photosynthesis and respiration. This can be day or night patterns, or seasonal patterns of light. You can investigate another factor that might limit productivity in the oceans: nitrates. <strong>Rate-limiting factors</strong> are factors such as light or nitrates that affect the total productivity in an ecosystem.
                          </p>

                          <p>
                            Nitrates contain the essential element nitrogen. Plants and all living things need nitrogen to grow. You can provide nitrogen to plants with fertilizer. You may have learned that nitrogen is a part of DNA and RNA. Nitrates and other nutrients are also often found in cold, deep waters of the ocean. Along coastlines, winds can move surface waters away from the shore. Colder, deeper waters move up to replace the surface waters. This process is called <strong>upwelling</strong>. Upwelling brings nitrates and other nutrients to the photic zone. The nutrients are vital to productivity in marine ecosystems. A lack of nitrates can limit the growth of primary producers.
                          </p>

                          <p>
                            Investigate with a partner whether light or nitrates limit productivity in the southern Atlantic.
                          </p>

                          <ol>
                            <li>Discuss with your partner whether you think light, nitrates, or both could be a limiting factor for productivity.</li>
                            <li>
                              Recall that the amount of chlorophyll indicates total productivity in the upper oceans. Use the RLF (rate-limiting factor) interactive to see how light and nitrates relate to the amount of chlorophyll. To use it, follow these steps.
                              <ol type="a">
                                <li>Open the RLF interactive. The top map shows chlorophyll. What colors indicate the highest levels of chlorophyll (<sup>mg</sup>&frasl;<sub>m<sup>3</sup></sub>)?</li>
                                <li>Select either nitrates or light as a rate-limiting factor. Explore the relationship between chlorophyll and the factor by clicking month by month. You can also view an animation by selecting "Loop Year."</li>
                                <li>Work with your partner to make a claim about whether light or nitrates limit productivity. With your claim, list key pieces of evidence to support your claim.Compare the other factor with chlorophyll levels.</li>
                              </ol>
                            </li>
                            <li>
                              Go to the Correlation Map. You can use data in this map to test the claim you made in step 2. To make this map, data for each small "dot" (location) is compared across the year. The map uses color to show if there is a <strong>positive relationship</strong>, <strong>no relationship</strong>, or <strong>negative relationship</strong> for each location. <br /><br />

                              <strong>Positive relationship:</strong> If two variables change in the same direction together (increase or decrease), then the relationship between the variables is positive. This is also called a direct relationship. For example, suppose you measured two variables on yourself from the time you were born until today: height and weight. You would see that, over time, as your height goes up, so does your weight. These variables have a strong, positive relationship. Strong, positive relationships are shown in orange. If a factor is a limiting factor, you would expect that productivity (chlorophyll) would go up when the factor also went up. A positive relationship indicates that a factor may be a limiting factor. In other words, it could be a cause. <br /><br />

                              <strong>No relationship:</strong> Some variables do not affect each other. If one goes up, the other may go up, go down, or not change. These cases are light blue or light green. Here is an example that may have no correlation, or perhaps a weak one: plotting whether hair length correlates to student height. There might not be an overall relationship, but could it depend on gender? You could test that! <br /><br />

                              <strong>Negative relationship:</strong> When two variables change in opposite directions, the variables have a negative, or inverse, relationship. Negative relationships are shown as purple or blue on the maps. For example, the more you eat, the less hungry you are and the less you can eat.
                            </li>
                          </ol>

                          <div className="col-12">
                            <iframe
                              className="embed-container w-100"
                              src="https://media.bscs.org/carbonconnections/interactives/rate-limiting-factor/rate-limiting-factor.html"
                              title="Rate limiting factor interactive"
                            />
                          </div>
                          <p>If you are unable to see the interactive, click <a href="https://media.bscs.org/carbonconnections/interactives/rate-limiting-factor/rate-limiting-factor.html" target="_blank" rel="noopener noreferrer">here&nbsp;<i className="fas fa-external-link-alt"></i></a> to open it in a new tab.</p>

                          <p className="lead">Other Rate-Limiting Factors in Oceans</p>
                          <p>
                            So far, you have seen that light can be a limiting factor for productivity on the land and in the oceans. If productivity is limited, carbon moves more slowly from the atmosphere to the ocean or land. What other limiting factors might also play a role?
                          </p>

                          <p>
                            You can select one of three areas to explore. For each area, you will be able to view the concentration of chlorophyll for each month across an entire year. You can compare this with three other variables that might affect the amount of chlorophyll.
                          </p>

                          <p>
                            <strong>Nitrate:</strong> Nitrates contain the essential element nitrogen. Plants, algae, and all living things need nitrogen to grow. A lack of nitrogen can limit the growth of producers. Scientists can use data from NASA satellites to calculate the concentration of nitrates. You added some growth medium to your algae. A main chemical of the growth medium is nitrate.
                          </p>

                          <p>
                            <strong>Light:</strong> NASA satellites measure the amount of sunlight across the globe. Plants and algae use light in the visible range (400 to 700 nm) for photosynthesis.
                          </p>

                          <p>
                            <strong>Aerosols:</strong> Aerosols are small solid or liquid particles suspended in air. A higher amount of aerosols may indicate a large amount of dust or smoke from a fire. Dust carries many important nutrients for plants and algae, including iron. So aerosols are a proxy for measuring the amount of iron. Iron may limit the growth of plants or algae.
                          </p>

                          <Row className="d-md-none">
                            <Col>
                              <img
                                className="rounded img-fluid mb-3"
                                src={ dustStorm21 }
                                alt="African dust storm"
                              />
                            </Col>
                          </Row>
                          <img
                            className="largeImageCenter img-fluid d-none d-md-block mb-3"
                            src={ dustStorm21 }
                            alt="African dust storm"
                          />

                          <p>
                            These satellite images show a dust storm in western Africa. Particulates are swept from the Sahara Desert to the Atlantic Ocean. Nutrients in the desert dust serve as a "fertilizer" for the marine food web in the Atlantic Ocean.
                          </p>

                          <p>
                            The next RLF Viewer has three other areas to investigate productivity and carbon movement in marine ecosystems. Select a region to investigate with your partner.
                          </p>

                          <ol start="4">
                            <li>Predict which variable you think will show a strong positive relationship with productivity in your region. In other words, when this variable is high, productivity (marine chlorophyll) will also be high.</li>
                            <li>Record in your science notebook what you and your partner see in the interactive. In general, when the variable has a high value, is productivity high? When the variable is low, is productivity low?</li>
                            <li>Explore each of the variables. You may also want to look at the correlation maps for each variable: They include valuable information. Which factors have the strongest correlation with chlorophyll? These would show as maps with the most orange in the correlation map.</li>
                            <li>
                              Summarize what you learned about rate-limiting factors in other parts of the Atlantic Ocean or the South China Sea.
                              <ol type="a">
                                <li>Which geographic regions did you investigate?</li>
                                <li>Which rate-limiting factor had the best correlation with ocean chlorophyll and productivity? Use a claim to answer this question.</li>
                                <li>List the evidence from the interactive that supports your claim in the previous step. Use examples of locations, colors, and variable values to support the correlation.</li>
                              </ol>
                            </li>
                          </ol>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>

                  <ButtonTop />

                  <ButtonNext
                    ButtonText="2.3 The Breathing Biosphere"
                    NextLink="/unit-2/2.3-the-breathing-biosphere/"
                  />

                  {isOpen && (
                    <Lightbox
                      mainSrc={images[photoIndex]}
                      nextSrc={images[(photoIndex + 1) % images.length]}
                      prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                      onCloseRequest={() => this.setState({ isOpen: false })}
                      onMovePrevRequest={() =>
                        this.setState({
                          photoIndex: (photoIndex + images.length - 1) % images.length,
                        })
                      }
                      onMoveNextRequest={() =>
                        this.setState({
                          photoIndex: (photoIndex + 1) % images.length,
                        })
                      }
                    />
                  )}

                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </Layout>
    )
  }
}

export default ExploringLimits22

export const query = graphql`
  query {
    image10: file(relativePath: { eq: "student-pages/unit-2/10_biomass_carbon_map.png" }) {
      ...rowImage
    }
    image11: file(relativePath: { eq: "student-pages/unit-2/11_algae_experiment.png" }) {
      ...rowImage
    }
    image12: file(relativePath: { eq: "student-pages/unit-2/12_photosynthesis.jpg" }) {
      ...rowImage
    }
    image13: file(relativePath: { eq: "student-pages/unit-2/13_seedtree.jpg" }) {
      ...rowImage
    }
    image14: file(relativePath: { eq: "student-pages/unit-2/14_aquarium.png" }) {
      ...rowImage
    }
    image16: file(relativePath: { eq: "student-pages/unit-2/16_plants_chlorophyll.jpg" }) {
      ...rowImage
    }
    image17: file(relativePath: { eq: "student-pages/unit-2/17_ocean_productivity.png" }) {
      ...rowImage
    }
    image18: file(relativePath: { eq: "student-pages/unit-2/18_map_mauna_loa.jpg" }) {
      ...rowImage
    }
    imageAE1: file(relativePath: { eq: "student-pages/unit-2/22-algae-experiment/01_algae_exp_day0.jpg" }) {
      ...rowImage
    }
    imageAE2: file(relativePath: { eq: "student-pages/unit-2/22-algae-experiment/02_algae_exp_day1.jpg" }) {
      ...rowImage
    }
    imageAE3: file(relativePath: { eq: "student-pages/unit-2/22-algae-experiment/03_algae_exp_day2.jpg" }) {
      ...rowImage
    }
    imageAE4: file(relativePath: { eq: "student-pages/unit-2/22-algae-experiment/04_algae_exp_day3.jpg" }) {
      ...rowImage
    }
    imageAE5: file(relativePath: { eq: "student-pages/unit-2/22-algae-experiment/05_algae_exp_day4.jpg" }) {
      ...rowImage
    }
    imageAE6: file(relativePath: { eq: "student-pages/unit-2/22-algae-experiment/06_algae_exp_day5.jpg" }) {
      ...rowImage
    }
    imageAE7: file(relativePath: { eq: "student-pages/unit-2/22-algae-experiment/07_algae_exp_day6.jpg" }) {
      ...rowImage
    }
    imageAE8: file(relativePath: { eq: "student-pages/unit-2/22-algae-experiment/08_algae_exp_day7.jpg" }) {
      ...rowImage
    }
    imageAE9: file(relativePath: { eq: "student-pages/unit-2/22-algae-experiment/09_algae_exp_day8.jpg" }) {
      ...rowImage
    }
    imageAE10: file(relativePath: { eq: "student-pages/unit-2/22-algae-experiment/10_algae_exp_day9.jpg" }) {
      ...rowImage
    }
  }
`
